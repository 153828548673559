import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore';
import type { FinalData, UserData } from 'ava-label-types';

export const userDataConverter = converterFirestore<UserData>();

export const finalDataConverter = converterFirestore<FinalData>();

function converterFirestore<T extends object>(): FirestoreDataConverter<T, T> {
  return {
    toFirestore: (item: T) => ({ ...item }),
    fromFirestore: (
      snapshot: QueryDocumentSnapshot<T>,
      options?: SnapshotOptions
    ) => {
      const data = snapshot.data(options);
      return data;
    },
  };
}
