import React, { useEffect, useState, useCallback } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions, getDocumentsValidation } from '../providers/firebase';
import LargeSpinnerLoader from '../components/LargeSpinnerLoader';
import { List, Alert, Select } from 'antd';
import VirtualList from 'rc-virtual-list';
import Avatar from 'antd/es/avatar/avatar';
import type { FinalValidationType, S3Prefix } from 'ava-label-types';
import type { SelectProps } from 'antd';

type LabelRender = SelectProps['labelRender'];

const CONTAINER_HEIGHT = 400;

type ConvoStatus = 'TODO' | 'DOING' | 'DONE';

interface ConvoItem {
  id: string;
  validationType?: FinalValidationType;
  blocked?: false | string;
}

interface ConvoCategory {
  data: ConvoItem[];
  setData: React.Dispatch<React.SetStateAction<ConvoItem[]>>;
  title: string;
  color: 'success' | 'info' | 'warning' | 'error';
  continuationToken: string | null | false;
  setContinuationToken: React.Dispatch<
    React.SetStateAction<string | null | false>
  >;
}

const Home = () => {
  const options = [
    { label: '🌎', value: '' },
    { label: '🇫🇷', value: 'fr' },
    { label: '🇺🇸', value: 'en' },
  ];
  const [selectedLang, setSelectedLang] = useState(options[0].value);
  const [isLoading, setIsLoading] = useState(false);

  const fetchConversations = useCallback(
    async (status: ConvoStatus, lang: string) => {
      setIsLoading(true);
      const { documents, continuationToken } = await fetchConversationsFromS3(
        status,
        null,
        lang
      );
      setIsLoading(false);
      return {
        conversations: documents.map((id) => ({ id })),
        token: continuationToken,
      };
    },
    []
  );

  const {
    conversations: todoConvos,
    setConversations: setTodoConvos,
    token: todoToken,
    setToken: setTodoToken,
    // refetch: refetchTodo,
  } = useConversations('TODO', selectedLang, fetchConversations);

  const {
    conversations: doingConvos,
    setConversations: setDoingConvos,
    token: doingToken,
    setToken: setDoingToken,
    // refetch: refetchDoing,
  } = useConversations('DOING', selectedLang, fetchConversations);

  const {
    conversations: doneConvos,
    setConversations: setDoneConvos,
    token: doneToken,
    setToken: setDoneToken,
    // refetch: refetchDone,
  } = useConversations('DONE', selectedLang, fetchConversations);

  const labelRender: LabelRender = ({ label }) => <span>{label}</span>;

  const convoCategories: Record<ConvoStatus, ConvoCategory> = {
    DOING: {
      data: doingConvos,
      setData: setDoingConvos,
      title: '🛠️ Conversations to finish',
      color: 'warning',
      continuationToken: doingToken,
      setContinuationToken: setDoingToken,
    },
    TODO: {
      data: todoConvos,
      setData: setTodoConvos,
      title: '✍️ New Conversations',
      color: 'info',
      continuationToken: todoToken,
      setContinuationToken: setTodoToken,
    },
    DONE: {
      data: doneConvos,
      setData: setDoneConvos,
      title: 'Finished Conversations (✅ or ❌:)',
      color: 'success',
      continuationToken: doneToken,
      setContinuationToken: setDoneToken,
    },
  };

  const handleScroll = (e: React.UIEvent<HTMLElement>, status: ConvoStatus) => {
    const { continuationToken } = convoCategories[status];
    if (
      continuationToken !== false &&
      Math.abs(
        e.currentTarget.scrollHeight -
          e.currentTarget.scrollTop -
          CONTAINER_HEIGHT
      ) <= 1
    ) {
      fetchConversationsFromS3(status, continuationToken, selectedLang).then(
        ({ documents, continuationToken }) => {
          if (documents.length > 0) {
            convoCategories[status].setData((prev) => [
              ...prev,
              ...documents.map((id) => ({ id })),
            ]);
            convoCategories[status].setContinuationToken(continuationToken);
          }
        }
      );
    }
  };

  const updateDoneConvoValidations = async () => {
    if (!doneConvos.length) return;

    const validationList = await getDocumentsValidation(
      doneConvos.map((convo) => convo.id)
    );
    const updatedConvos = validationList.map((validationType, index) => ({
      ...doneConvos[index],
      validationType,
    }));
    setDoneConvos(updatedConvos);
  };

  const checkBlockedDocuments = async () => {
    if (!doingConvos.length) return;

    const response = await httpsCallable<
      { convoIds: string[] },
      { isBlocked: false | string; id: string }[]
    >(
      functions,
      'checkAreDocumentBlocked'
    )({
      convoIds: doingConvos.map((convo) => convo.id),
    });

    const updatedConvos = response.data.map(
      ({ isBlocked, id }) =>
        ({
          ...doingConvos.find((convo) => convo.id === id),
          blocked: isBlocked,
        }) as ConvoItem
    );

    setDoingConvos(updatedConvos);
  };

  useEffect(() => {
    updateDoneConvoValidations();
  }, [doneConvos.length]);

  useEffect(() => {
    checkBlockedDocuments();
  }, [doingConvos.length]);

  const handleLanguageChange = (lang: string) => {
    setDoneToken(null);
    setDoingToken(null);
    setTodoToken(null);
    setSelectedLang(lang);
  };

  if (isLoading) {
    return <LargeSpinnerLoader />;
  }

  return (
    <main>
      <Select
        options={options}
        labelRender={labelRender}
        style={{ width: '60px' }}
        value={selectedLang}
        onChange={handleLanguageChange}
      />
      {Object.keys(convoCategories).map((status) => (
        <ConvoCategorySection
          key={status}
          status={status as ConvoStatus}
          category={convoCategories[status as ConvoStatus]}
          handleScroll={handleScroll}
        />
      ))}
    </main>
  );
};

function useConversations(
  status: ConvoStatus,
  lang: string,
  fetchFunction: (
    status: ConvoStatus,
    lang: string
  ) => Promise<{ conversations: ConvoItem[]; token: string | false }>
) {
  const [conversations, setConversations] = useState<ConvoItem[]>([]);
  const [token, setToken] = useState<string | null | false>(null);

  const refetch = useCallback(async () => {
    const { conversations: newConversations, token: newToken } =
      await fetchFunction(status, lang);
    setConversations(newConversations);
    setToken(newToken);
  }, [status, lang, fetchFunction]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return { conversations, setConversations, token, setToken, refetch };
}

async function fetchConversationsFromS3(
  prefix: S3Prefix,
  continuationToken?: string | null | false,
  lang?: string
): Promise<{ documents: string[]; continuationToken: string | false }> {
  if (continuationToken === false)
    return { documents: [], continuationToken: false };

  const response = await httpsCallable<
    { prefix: S3Prefix; continuationToken?: string | null; lang?: string },
    { documents: string[]; continuationToken: string | false }
  >(
    functions,
    'getAllSegmentsFiles'
  )({ prefix, continuationToken, lang });

  return response.data;
}

const ConvoCategorySection = ({
  status,
  category,
  handleScroll,
}: {
  status: ConvoStatus;
  category: ConvoCategory;
  handleScroll: (e: React.UIEvent<HTMLElement>, status: ConvoStatus) => void;
}) => {
  const { data, title, color } = category;

  return (
    <section>
      <h1>{title}</h1>
      <List>
        <VirtualList
          data={data}
          height={CONTAINER_HEIGHT}
          itemHeight={30}
          itemKey="id"
          onScroll={(e) => handleScroll(e, status)}
        >
          {(item) => (
            <List.Item
              key={item.id}
              style={{ display: 'flex', justifyContent: 'start' }}
            >
              <span style={{ marginRight: '15px' }}>
                {item.id.split('-')[1] === 'fr' ? '🇫🇷' : '🇺🇸'}
              </span>
              {item.blocked && (
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={`https://ui-avatars.com/api/?name=${item.blocked.replace(' ', '+')}`}
                    />
                  }
                  title={`This document is blocked by ${item.blocked}`}
                />
              )}
              <a href={`/dashboard/${item.id}`}>
                <Alert
                  type={item.validationType === 'REJECTED' ? 'error' : color}
                  message={item.id.split('-')[0]}
                />
              </a>
            </List.Item>
          )}
        </VirtualList>
      </List>
    </section>
  );
};

export default Home;
