import React, { useCallback, useEffect, useMemo, useState } from 'react';
import WavesurferPlayer from '@wavesurfer/react';
import _, { sortBy } from 'lodash';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline';
import './MultiTrack.css';
import { Col, Input, Modal, Progress, Row, Typography, Button } from 'antd';
import {
  CloseOutlined,
  CheckCircleOutlined,
  StopOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import { createProgrammaticElementWithTextAndButton } from './Segments';

import { changeColorOpacity } from '../helpers/formater';
import type {
  SegmentUI,
  Region,
  SpeakerSelect,
  FinalValidationType,
} from 'ava-label-types';
import { speakerColors } from '../helpers/constants';
import TextArea from 'antd/es/input/TextArea';
import { useHotkeys } from 'react-hotkeys-hook';
import { TEXT_COLORS } from '../helpers/constants';
import SpeakerTag from './SpeakerTag';
import {
  UNDEFINED_SPEAKER_COLOR,
  UNDEFINED_SPEAKER_ID,
} from '../helpers/formater';
import { httpsCallable } from 'firebase/functions';
import { functions, updateSegment } from '../providers/firebase';
import ToggleButton from './ToggleButton';
import { v7 as uuidv7 } from 'uuid';
import { extractNumber } from '../helpers/speaker';

const zoomStep = 50;
const DEFAULT_ZOOM_LEVEL = 100;

interface MultiTrackProps {
  audioDomElement: HTMLMediaElement;
  urlSigned: string;
  selectedSpeaker: string; //ID of the selected speaker
  speakers: SpeakerSelect;
  pause: () => void;
  regionsParams?: SegmentUI[];
  handleUpdateSegment: typeof updateSegment;
  isReady: boolean;
  convoId: string;
  handleSave: () => void;
  handleIsModified: () => void;
  isModified: boolean;
  setFinalValidationTypeHandler: (
    finalValidationType: FinalValidationType | null
  ) => void;
  numberOfChannels: number;
  peaks?: number[][];
}

function MultiTrack({
  peaks,
  audioDomElement,
  selectedSpeaker,
  urlSigned,
  speakers,
  pause,
  regionsParams = [],
  handleUpdateSegment,
  isReady,
  convoId,
  handleSave,
  handleIsModified,
  setFinalValidationTypeHandler,
  numberOfChannels,
}: MultiTrackProps) {
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null);
  const regionsPlugin = useMemo(() => RegionsPlugin.create(), []);
  const timeLinePlugin = useMemo(() => TimelinePlugin.create(), []);
  const overlay = useMemo(() => [{ overlay: true }], []);
  const plugins = useMemo(
    () => [regionsPlugin, timeLinePlugin],
    [timeLinePlugin, regionsPlugin]
  );
  const [regions, setRegions] = useState<SegmentUI[]>([]);
  const [loop, setLoop] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [activeRegion, setActiveRegion] = useState<Region[]>([]);
  const [newContent, setNewContent] = useState({
    content: '',
    subText: '',
    color: '',
  });
  const [zoomLevel, setZoomLevel] = useState(DEFAULT_ZOOM_LEVEL); // Default zoom level
  const [validatedRegions, setValidatedRegions] = useState({
    count: _.countBy(regionsParams, 'validated').true || 0,
    total: regionsParams.length,
  });
  const sortedSpeakers = useMemo(
    () =>
      sortBy(Object.entries(speakers), ([, value]) =>
        extractNumber(value.name)
      ),
    [speakers]
  );

  const memoizedProgress = useMemo(() => {
    if (!regions) return 0;
    const validated = regions.filter((r) => !!r.validated);
    if (!validated.length) return 0;
    return _.round((validated.length / regions.length) * 100, 2);
  }, [regions]);

  const lastActiveRegion = useMemo(() => {
    if (_.isEmpty(activeRegion)) return null;
    return _.last(activeRegion);
  }, [activeRegion]);

  const memoizedRegionContent = useMemo(() => {
    if (!lastActiveRegion) return;
    const content =
      lastActiveRegion.content?.querySelector('.region-text-content')
        ?.textContent || '';

    const subText =
      lastActiveRegion.content?.querySelector('.region-sub-content')
        ?.textContent || '';

    return {
      content,
      subText,
      color: lastActiveRegion.color,
    };
  }, [activeRegion, lastActiveRegion?.content, lastActiveRegion?.color]);

  // KEYBOARD
  useHotkeys(['e'], (event) => {
    event.preventDefault();
    if (lastActiveRegion) {
      pause();
      setIsDialogVisible(true);
    }
  });
  useHotkeys(['a'], () => {
    if (lastActiveRegion) {
      validateRegion();
    }
  });
  useHotkeys(['u'], () => {
    if (lastActiveRegion) {
      unvalidateRegion();
    }
  });
  useHotkeys(['Enter'], () => {
    if (isDialogVisible) {
      setIsDialogVisible(false);
      updateRegionContent();
    }
  });
  useHotkeys(['n'], () => {
    goToNextRegion();
  });
  useHotkeys('m', () => {
    goToNextRegion(true);
  });
  useHotkeys(['l'], () => {
    setLoop((prev) => !prev);
  });

  // Add regions in list at load
  useEffect(() => {
    if (!regionsParams.length) return;
    setRegions(regionsParams);
  }, [regionsParams]);

  // REGIONS VALIDATION
  useEffect(() => {
    if (regionsParams.length === validatedRegions.total) {
      return;
    }
    setValidatedRegions((prev) => {
      return {
        ...prev,
        total: regionsParams.length,
        count: _.countBy(regionsParams, 'validated').true || 0,
      };
    });
  }, [regionsParams]);

  useEffect(() => {
    if (!wavesurfer || !regionsPlugin) return;
    regionsPlugin.enableDragSelection({
      id: 'none',
      color: 'rgba(0, 0, 0, 0.1)', // black transparent
    });
    wavesurfer?.zoom(zoomLevel);
  }, [wavesurfer, regionsPlugin]);
  // ADD REGIONS AT LOAD
  useEffect(() => {
    if (!wavesurfer || !regionsPlugin || !regions?.length) return;

    const addRegionAsync = async (region: SegmentUI) => {
      const regionAlreadyShown = regionsPlugin
        .getRegions()
        .find(({ id }) => id !== 'none' && id === region.id);
      if (regionAlreadyShown) return;

      let regionRef = regionsPlugin
        .getRegions()
        .find(({ id }) => id === region.id)!;
      if (!regionRef) {
        regionRef = regionsPlugin.addRegion({
          ...region,
          id: region.id,
          drag: !region.validated,
          resize: !region.validated,
          contentEditable: !region.validated,
          color:
            changeColorOpacity(
              speakerColors[region.speakerId] ?? speakerColors[selectedSpeaker],
              0.5
            ) ?? 'rgba(0, 0, 0, 0.1)',
        });
      }

      const element = await createProgrammaticElementWithTextAndButton({
        text: region?.speakerId
          ? (speakers[region.speakerId]?.name ?? 'UNDEFINED')
          : (speakers[selectedSpeaker]?.name ?? 'UNDEFINED'),
        subText: region.subContent,
        onClick: () => {
          onRemovedRegion(regionRef);
        },
        margin: 1,
        deleteButton: !region.validated,
      });

      if (region.id !== 'none') {
        regionRef.setContent(element.current!);
        return;
      }

      const newId = `region-${uuidv7()}`;
      regionRef.setOptions({
        start: region.start,
        color: changeColorOpacity(speakerColors[selectedSpeaker], 0.5),
        id: newId,
        content: element.current!,
        contentEditable: true,
      });

      await updateSegment('ADD', convoId, [
        {
          id: newId,
          speaker_id: selectedSpeaker,
          transcript: '',
          start_time: region.start,
          end_time: region.end,
        },
      ]).catch((e) => {
        Modal.error({
          title: '❌ Error, Please refresh the page after 5s.',
          content: `An error occurred while adding the region: ${e.message} you work will not be saved anymore`,
        });
      });
      handleSave();
    };

    const addRegions = async () => {
      await Promise.all(regions.map(addRegionAsync));
    };

    addRegions();
  }, [wavesurfer, regions, regionsPlugin, setRegions, onRemovedRegion]);

  // REGION EVENTS
  useEffect(() => {
    if (!wavesurfer) return;

    regionsPlugin.on('region-created', onCreatedRegion);
    regionsPlugin.on('region-out', onOutRegion);
    regionsPlugin.on('region-in', onInRegion);
    regionsPlugin.on('region-clicked', onInRegion);
    regionsPlugin.on('region-double-clicked', onDbClickRegion);
    regionsPlugin.on('region-updated', onUpdatedRegion);
    wavesurfer.once('decode', () => {
      wavesurfer.zoom(zoomLevel);
    });

    return () => {
      regionsPlugin.un('region-created', onCreatedRegion);
      regionsPlugin.un('region-updated', onUpdatedRegion);
      regionsPlugin.un('region-out', onOutRegion);
      regionsPlugin.un('region-in', onInRegion);
      regionsPlugin.un('region-clicked', onInRegion);
    };
  }, [
    wavesurfer,
    selectedSpeaker,
    regionsPlugin,
    lastActiveRegion,
    loop,
    isReady,
    setRegions,
    regions,
  ]);

  //---------------------------------------------------------//
  //                  REGIONS EVENTS HANDLER                 //
  // --------------------------------------------------------//

  const onCreatedRegion = useCallback(
    async (region: Region) => {
      // Here we will add region in the list, the effect will do the rest
      const isRegionInProps = _.find(regionsParams, { id: region.id });
      if (isRegionInProps) return;

      //@ts-expect-error type are different but compatible
      setRegions((prev) => {
        if (prev.find((r) => r.id === region.id)) {
          return prev;
        }
        return [...prev, region];
      });
    },
    [regionsParams]
  );

  const onInRegion = useCallback(
    (region: Region) => {
      if (lastActiveRegion && loop && region.start <= lastActiveRegion.end)
        return;

      const content =
        region.content?.querySelector('.region-text-content')?.textContent ||
        '';

      const subText =
        region.content?.querySelector('.region-sub-content')?.textContent || '';

      const color = region.color;

      const { id } = region;
      const allRegions: Region[] = [];
      if (!allRegions) return;
      allRegions.forEach((r) => {
        const isCurrentRegion = r.id === id;
        r.setOptions({
          start: r.start,
          contentEditable: isCurrentRegion,
          color: changeColorOpacity(r.color, isCurrentRegion ? 0.7 : 0.5),
        });
      });

      if (!loop || !lastActiveRegion) {
        setActiveRegion((r) => [...r, region]);
      }
      setNewContent({ content, subText, color });
    },
    [lastActiveRegion, loop]
  );

  const onOutRegion = useCallback(
    (region: Region) => {
      if (lastActiveRegion?.id === region.id && loop) {
        region.play();
      } else {
        setActiveRegion((r) => r.filter((r) => r.id !== region.id));
      }
    },
    [lastActiveRegion, loop]
  );

  const onUpdatedRegion = useCallback(
    (region: Region) => {
      handleIsModified();
      handleUpdateSegment('METADATA', convoId, [
        {
          id: region.id,
          start_time: region.start,
          end_time: region.end,
        },
      ])
        .then(() => {
          handleSave();
        })
        .catch((e) => {
          Modal.error({
            title: '❌ Error, Please refresh the page after 5s.',
            content: `An error occurred while adding the region: ${e.message} you work will not be saved anymore`,
          });
        });
    },
    [handleIsModified, handleUpdateSegment, convoId, handleSave]
  );

  const onDbClickRegion = useCallback(
    (region: Region) => {
      if (!lastActiveRegion?.contentEditable) return;
      setIsDialogVisible(true);
    },
    [lastActiveRegion]
  );
  //---------------------------------------------------------//
  //                  REGIONS HELPERS GETTERS                //
  // --------------------------------------------------------//

  function getAllRegions() {
    return _.orderBy(regionsPlugin?.getRegions(), (r) => r.start, 'asc');
  }

  function getAllUndefinedRegions() {
    return _.filter(
      getAllRegions(),
      (r) =>
        r.content?.querySelector('.region-text-content')?.textContent ===
        UNDEFINED_SPEAKER_ID
    );
  }

  const getRegionsBySpeakerName = (speakerName: string) => {
    return _.filter(getAllRegions(), (region) => {
      const content =
        region.content?.querySelector('.region-text-content')?.textContent ||
        '';

      return content.includes(speakerName);
    });
  };

  //---------------------------------------------------------//
  //                  REGIONS HELPERS SETTERS                //
  // --------------------------------------------------------//

  function updateRegionSpeakerName(
    oldSpeakerName: string,
    newSpeakerName?: string
  ) {
    const regions = getRegionsBySpeakerName(oldSpeakerName);
    regions.forEach((region) => {
      const regionContent = region.content!;
      const res = regionContent.querySelector('.region-text-content')!;
      res.textContent = newSpeakerName ?? UNDEFINED_SPEAKER_ID;
      if (!newSpeakerName) {
        //@ts-expect-error for the moment it's ok
        region.setOptions({
          color: UNDEFINED_SPEAKER_COLOR,
        });
      }
    });
  }

  function onRemovedRegion(region: Region) {
    region.remove();

    handleIsModified();

    setRegions((regions) => regions.filter((r) => r.id !== region.id));

    setActiveRegion([]);
    updateSegment('REMOVE', convoId, [{ id: region.id }])
      .then(() => {
        handleSave();
      })
      .catch(() => {
        regionsPlugin?.addRegion(region);
      });
  }

  function goToNextRegion(undefinedSpeaker = false) {
    const currentTime = wavesurfer?.getCurrentTime() || 0;
    const allRegions = undefinedSpeaker
      ? getAllUndefinedRegions()
      : getAllRegions();
    const nextRegion = _.find(allRegions, (region) => {
      const roundedStart = _.round(region.start, 5);
      const roundedCurrentTime = _.round(currentTime, 5);
      return roundedStart > roundedCurrentTime && region.resize;
    });
    if (nextRegion && wavesurfer) {
      const seekInPercentage =
        (nextRegion.start + 0.00001) / wavesurfer.getMediaElement().duration;
      wavesurfer?.seekTo(seekInPercentage);
    } else {
      setActiveRegion([]);
    }
  }

  async function validateRegion() {
    if (!lastActiveRegion) return;
    await updateRegionContent({ validate: true });

    handleUpdateSegment('VALIDATE', convoId, [
      {
        id: lastActiveRegion.id,
      },
    ])
      .then(() => {
        handleSave();
        lastActiveRegion.contentEditable = false;
      })
      .catch((e) => {
        Modal.error({
          title: '❌ Error, Please refresh the page after 5s.',
          content: `An error occurred while adding the region: ${e.message} you work will not be saved anymore`,
        });
      });
    // goToNextRegion(); // this is in fact a bad behavior for scribe they prefer a+n
  }

  async function unvalidateRegion() {
    if (!lastActiveRegion) return;
    await updateRegionContent({ validate: false });

    handleUpdateSegment('UNVALIDATE', convoId, [{ id: lastActiveRegion.id }])
      .then(() => {
        handleSave();
        lastActiveRegion.contentEditable = true;
      })
      .catch((e) => {
        Modal.error({
          title: '❌ Error, Please refresh the page after 5s.',
          content: `An error occurred while adding the region: ${e.message} you work will not be saved anymore`,
        });
      });
    // goToNextRegion(); // this is not wanted ATM
  }

  async function updateRegionContent({
    validate,
  }: { validate?: boolean } = {}) {
    if (!lastActiveRegion) return;
    handleIsModified();
    const id = lastActiveRegion.id;
    const allRegions = getAllRegions();
    const regionToChange = _.filter(allRegions, { id })[0];
    if (validate !== undefined) {
      const newRegions: SegmentUI[] = regions.map((r) => {
        if (r.id === id) {
          return {
            ...r,
            validated: validate,
          };
        }
        return r;
      });
      setRegions(newRegions);
    }
    setActiveRegion([...activeRegion, regionToChange]);

    const content = await createProgrammaticElementWithTextAndButton({
      text: newContent.content,
      subText: newContent.subText,
      onClick: () => onRemovedRegion(regionToChange),
      deleteButton: !validate,
    });

    // this is needed because the creation of react component can take time and first render empty
    regionToChange.setOptions({
      start: regionToChange.start,
      //@ts-expect-error for the moment it's ok
      content: content.current,
      color: newContent.color,
      resize: !validate,
      drag: !validate,
    });

    await handleUpdateSegment('METADATA', convoId, [
      {
        id,
        speaker_id: _.findKey(speakers, { name: newContent.content }),
        transcript: newContent.subText,
      },
    ]).catch((e) => {
      Modal.error({
        title: '❌ Error, Please refresh the page after 5s.',
        content: `An error occurred while adding the region: ${e.message} you work will not be saved anymore`,
      });
    });
    handleSave();
  }

  //---------------------------------------------------------//
  //                  GLOBAL HELPERS                         //
  // --------------------------------------------------------//

  function onReady(ws: WaveSurfer) {
    if (!ws || !audioDomElement) return;
    setWavesurfer(ws);
    // we need to dynamically add the controls after
    document
      .querySelector('#waveform > div > div')
      ?.shadowRoot?.appendChild(audioDomElement);
  }
  function handleChangeZoom(zoomIncr: number) {
    setZoomLevel((prev) => {
      const newZoom = prev + zoomIncr;
      wavesurfer?.zoom(newZoom);
      return Math.max(newZoom, 0);
    });
  }

  const zoomIn = () => {
    handleChangeZoom(zoomStep);
  };

  const zoomOut = () => {
    handleChangeZoom(-zoomStep);
  };

  const toggleLoop = () => {
    setLoop((prev) => !prev);
  };

  async function closeTheDocument(
    convoid: string,
    finalValidationType: FinalValidationType
  ) {
    if (
      globalThis.confirm(
        `Are you sure you want the document to be  ${finalValidationType.toLowerCase()} ?`
      )
    ) {
      const { data } = await saveFinalDocumentState({
        convoId,
        finalValidationType,
      });
      if (!data) {
        return;
      }
      setFinalValidationTypeHandler(finalValidationType);
    }
  }

  const Waveform = () => (
    <>
      <div>
        <Col>
          <Row justify="space-evenly" align="middle">
            <Button
              className="validate-button"
              type="default"
              icon={<CheckCircleOutlined />}
              title={
                memoizedProgress !== 100
                  ? 'You cannot validate the whole, you must validate each segments first'
                  : 'Validate the whole document'
              }
              disabled={memoizedProgress !== 100}
              size="middle"
              onClick={async () => closeTheDocument(convoId, 'VALIDATED')}
            >
              Validate
            </Button>
            <Button
              className="reject-button"
              type="default"
              icon={<StopOutlined />}
              title="Reject completely this audio document"
              size="middle"
              onClick={async () => closeTheDocument(convoId, 'REJECTED')}
            >
              Reject
            </Button>
          </Row>
          <Row justify="space-evenly" align="middle">
            <Progress
              className="progress-width-50"
              percent={memoizedProgress}
            />
            <label>
              <Input
                list="zoomoptions"
                type="range"
                min="0"
                max="1000"
                onChange={(e) => {
                  const minPxPerSec = parseInt(
                    (e.target as HTMLInputElement).value
                  );
                  wavesurfer?.zoom(minPxPerSec);
                  setZoomLevel(minPxPerSec);
                }}
                value={zoomLevel}
              />
            </label>
            <datalist id="zoomoptions">
              <option value={DEFAULT_ZOOM_LEVEL}></option>
            </datalist>
          </Row>
        </Col>
        <div id="wave-timeline"></div>
      </div>

      <div className="fancy-border" id="waveform">
        <WavesurferPlayer
          // backend="WebAudio"
          waveColor="rgb(200, 0, 200)"
          progressColor="rgb(100, 0, 100)"
          url={urlSigned}
          onReady={onReady}
          autoScroll={true}
          mediaControls={true}
          barHeight={0.8}
          dragToSeek={true}
          sampleRate={16000}
          plugins={plugins}
          minPxPerSec={20}
          splitChannels={overlay}
          height={500 / numberOfChannels}
          media={audioDomElement}
          {...(peaks && peaks.length ? { peaks } : {})}
        />
      </div>
    </>
  );

  const RegionController = () => (
    <div>
      <div
        style={{
          marginTop: '1em',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <ToggleButton
          type={loop ? 'primary' : 'default'}
          onClick={toggleLoop}
          shape="circle"
          reverseCallback={pause}
          enabled={loop}
          icon={<RedoOutlined />}
          title="Loop"
        ></ToggleButton>
      </div>
      <Modal
        open={isDialogVisible}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => {
          setIsDialogVisible(false);
          updateRegionContent();
        }}
        onCancel={() => {
          setIsDialogVisible(false);
        }}
        closeIcon={
          <CloseOutlined
            onClick={() => {
              setIsDialogVisible(false);
            }}
          />
        }
      >
        {activeRegion && (
          <div>
            <Row align="middle" style={{ marginBottom: 5 }}>
              <Col span={5}>Speaker</Col>
              <Col span={15}>
                {sortedSpeakers.map(([speakerId, speaker]) => (
                  <SpeakerTag
                    speakerId={speakerId}
                    selected={newContent.content === speaker.name}
                    key={speakerId}
                    onClick={() => {
                      setNewContent((x) => ({
                        ...x,
                        content: speakers[speakerId].name,
                        color: changeColorOpacity(
                          speakerColors[speakerId],
                          0.5
                        ),
                      }));
                    }}
                  >
                    <Typography>{speaker.name}</Typography>
                  </SpeakerTag>
                ))}
              </Col>
            </Row>
            <Row align="middle">
              <Col span={5}>Transcript</Col>
              <Col span={15}>
                <TextArea
                  rows={6}
                  value={newContent.subText}
                  onChange={(e) =>
                    setNewContent((x) => ({
                      ...x,
                      subText: e.target.value,
                    }))
                  }
                  placeholder="Update content"
                />{' '}
              </Col>
            </Row>
          </div>
        )}
      </Modal>
      <div>
        <h3>Active region</h3>
        <Typography.Title level={2} style={{ color: TEXT_COLORS.darkBlue }}>
          Speaker
        </Typography.Title>
        <div style={{ backgroundColor: memoizedRegionContent?.color }}>
          {memoizedRegionContent?.content}
        </div>

        <Typography.Title level={2} style={{ color: TEXT_COLORS.darkBlue }}>
          Transcript
        </Typography.Title>
        <Typography.Title
          level={3}
          style={{
            color: TEXT_COLORS.lightBlue,
            fontSize: 15,
            maxHeight: 350,
            overflow: 'auto',
            wordWrap: 'break-word',
          }}
        >
          {memoizedRegionContent?.subText}
        </Typography.Title>
      </div>
    </div>
  );

  return {
    Waveform,
    RegionController,
    zoomIn,
    zoomOut,
    updateRegionSpeakerName,
  };
}

export default MultiTrack;

type DocumentClosePayload = {
  convoId: string;
  finalValidationType: FinalValidationType;
};
async function saveFinalDocumentState(payload: DocumentClosePayload) {
  return httpsCallable<
    { convoId: string; finalValidationType: FinalValidationType },
    { signedUrl: string }
  >(
    functions,
    'finalValidationDocument'
  )(payload);
}
