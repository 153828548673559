export const mockedAvaId = 'testAvaId';

// use transparent colors with #RRGGBBAA with AA = 80 (50%)
// You should mock 4 speakers
export const speakerColors: Record<string, string> = {
  speaker_1: 'rgba(255, 204, 153, 1)', // Peach
  speaker_2: 'rgba(173, 216, 230, 1)', // Light Blue
  speaker_3: 'rgba(240, 230, 140, 1)', // Khaki
  speaker_4: 'rgba(255, 182, 193, 1)', // Light Pink
  speaker_5: 'rgba(152, 251, 152, 1)', // Pale Green
  speaker_6: 'rgba(221, 160, 221, 1)', // Plum
  speaker_7: 'rgba(176, 224, 230, 1)', // Powder Blue
  speaker_8: 'rgba(255, 160, 122, 1)', // Light Salmon
  speaker_9: 'rgba(216, 191, 216, 1)', // Thistle
  speaker_10: 'rgba(255, 222, 173, 1)', // Navajo White
  speaker_11: 'rgba(175, 238, 238, 1)', // Pale Turquoise
  speaker_12: 'rgba(255, 228, 196, 1)', // Bisque
  speaker_13: 'rgba(221, 221, 221, 1)', // Light Gray
  speaker_14: 'rgba(240, 255, 240, 1)', // Honeydew
  speaker_15: 'rgba(253, 245, 230, 1)', // Old Lace
  speaker_16: 'rgba(255, 250, 205, 1)', // Lemon Chiffon
  speaker_17: 'rgba(245, 255, 250, 1)', // Mint Cream
  speaker_18: 'rgba(255, 239, 213, 1)', // Papaya Whip
  speaker_19: 'rgba(230, 230, 250, 1)', // Lavender
  speaker_20: 'rgba(255, 240, 245, 1)', // Lavender Blush
  speaker_21: 'rgba(204, 255, 204, 1)', // Pale Mint
  speaker_22: 'rgba(255, 228, 225, 1)', // Misty Rose
  speaker_23: 'rgba(240, 248, 255, 1)', // Alice Blue
  speaker_24: 'rgba(255, 255, 224, 1)', // Light Yellow
  speaker_25: 'rgba(224, 255, 255, 1)', // Light Cyan
  speaker_26: 'rgba(250, 235, 215, 1)', // Antique White
  speaker_27: 'rgba(255, 218, 185, 1)', // Peach Puff
  speaker_28: 'rgba(211, 211, 211, 1)', // Light Grey
  speaker_29: 'rgba(255, 192, 203, 1)', // Pink
  speaker_30: 'rgba(176, 196, 222, 1)', // Light Steel Blue
  speaker_31: 'rgba(255, 235, 205, 1)', // Blanched Almond
  speaker_32: 'rgba(238, 232, 170, 1)', // Pale Goldenrod
  speaker_33: 'rgba(255, 250, 250, 1)', // Snow
  speaker_34: 'rgba(240, 255, 255, 1)', // Azure
  speaker_35: 'rgba(245, 245, 220, 1)', // Beige
  speaker_36: 'rgba(255, 228, 181, 1)', // Moccasin
  speaker_37: 'rgba(255, 248, 220, 1)', // Cornsilk
  speaker_38: 'rgba(188, 143, 143, 1)', // Rosy Brown
  speaker_39: 'rgba(255, 215, 0, 1)', // Gold
  speaker_40: 'rgba(189, 183, 107, 1)', // Dark Khaki
  speaker_41: 'rgba(152, 251, 152, 1)', // Pale Green
  speaker_42: 'rgba(238, 130, 238, 1)', // Violet
  speaker_43: 'rgba(135, 206, 235, 1)', // Sky Blue
  speaker_44: 'rgba(244, 164, 96, 1)', // Sandy Brown
  speaker_45: 'rgba(218, 112, 214, 1)', // Orchid
  speaker_46: 'rgba(176, 224, 230, 1)', // Powder Blue
  speaker_47: 'rgba(255, 105, 180, 1)', // Hot Pink
  speaker_48: 'rgba(205, 92, 92, 1)', // Indian Red
  speaker_49: 'rgba(240, 128, 128, 1)', // Light Coral
  speaker_50: 'rgba(250, 128, 114, 1)', // Salmon
  speaker_51: 'rgba(233, 150, 122, 1)', // Dark Salmon
  speaker_52: 'rgba(255, 160, 122, 1)', // Light Salmon
  speaker_53: 'rgba(255, 165, 0, 1)', // Orange
  speaker_54: 'rgba(255, 215, 0, 1)', // Gold
  speaker_55: 'rgba(255, 255, 0, 1)', // Yellow
  speaker_56: 'rgba(154, 205, 50, 1)', // Yellow Green
  speaker_57: 'rgba(124, 252, 0, 1)', // Lawn Green
  speaker_58: 'rgba(127, 255, 0, 1)', // Chartreuse
  speaker_59: 'rgba(0, 255, 127, 1)', // Spring Green
  speaker_60: 'rgba(0, 255, 255, 1)', // Cyan
  speaker_61: 'rgba(0, 191, 255, 1)', // Deep Sky Blue
  speaker_62: 'rgba(30, 144, 255, 1)', // Dodger Blue
  speaker_63: 'rgba(100, 149, 237, 1)', // Cornflower Blue
  speaker_64: 'rgba(123, 104, 238, 1)', // Medium Slate Blue
  speaker_65: 'rgba(148, 0, 211, 1)', // Dark Violet
  speaker_66: 'rgba(186, 85, 211, 1)', // Medium Orchid
  speaker_67: 'rgba(199, 21, 133, 1)', // Medium Violet Red
  speaker_68: 'rgba(219, 112, 147, 1)', // Pale Violet Red
  speaker_69: 'rgba(255, 20, 147, 1)', // Deep Pink
  speaker_70: 'rgba(255, 69, 0, 1)', // Orange Red
  speaker_71: 'rgba(255, 140, 0, 1)', // Dark Orange
  speaker_72: 'rgba(255, 215, 0, 1)', // Gold
  speaker_73: 'rgba(218, 165, 32, 1)', // Goldenrod
  speaker_74: 'rgba(184, 134, 11, 1)', // Dark Goldenrod
  speaker_75: 'rgba(188, 143, 143, 1)', // Rosy Brown
  speaker_76: 'rgba(205, 133, 63, 1)', // Peru
  speaker_77: 'rgba(210, 105, 30, 1)', // Chocolate
  speaker_78: 'rgba(139, 69, 19, 1)', // Saddle Brown
  speaker_79: 'rgba(160, 82, 45, 1)', // Sienna
  speaker_80: 'rgba(165, 42, 42, 1)', // Brown
  speaker_81: 'rgba(128, 0, 0, 1)', // Maroon
  speaker_82: 'rgba(255, 99, 71, 1)', // Tomato
  speaker_83: 'rgba(255, 127, 80, 1)', // Coral
  speaker_84: 'rgba(255, 160, 122, 1)', // Light Salmon
  speaker_85: 'rgba(233, 150, 122, 1)', // Dark Salmon
  speaker_86: 'rgba(250, 128, 114, 1)', // Salmon
  speaker_87: 'rgba(255, 69, 0, 1)', // Orange Red
  speaker_88: 'rgba(255, 140, 0, 1)', // Dark Orange
  speaker_89: 'rgba(255, 165, 0, 1)', // Orange
  speaker_90: 'rgba(255, 215, 0, 1)', // Gold
  speaker_91: 'rgba(255, 255, 0, 1)', // Yellow
  speaker_92: 'rgba(154, 205, 50, 1)', // Yellow Green
  speaker_93: 'rgba(124, 252, 0, 1)', // Lawn Green
  speaker_94: 'rgba(127, 255, 0, 1)', // Chartreuse
  speaker_95: 'rgba(0, 255, 127, 1)', // Spring Green
  speaker_96: 'rgba(0, 250, 154, 1)', // Medium Spring Green
  speaker_97: 'rgba(0, 255, 255, 1)', // Cyan
  speaker_98: 'rgba(0, 191, 255, 1)', // Deep Sky Blue
  speaker_99: 'rgba(30, 144, 255, 1)', // Dodger Blue
  speaker_100: 'rgba(70, 130, 180, 1)', // Steel Blue
};

export enum TEXT_COLORS {
  lightBlue = '#1890ff',
  darkBlue = '#003366',
}
