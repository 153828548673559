export const createHtmlElementButton = (
  onClick: () => void
): HTMLButtonElement => {
  const button = document.createElement('button');
  button.innerHTML = 'X';
  button.onclick = (event) => {
    event.stopPropagation(); // Stop event propagation
    onClick();
  };
  button.style.marginLeft = 'auto'; // Push the button to the right in a flex container
  button.style.cursor = 'pointer'; // Change cursor to pointer for better UX
  button.style.position = 'absolute'; // Set position to relative
  button.style.zIndex = '10000'; // Set a very high z-index value

  // Styling to make the button look good and smaller
  button.style.backgroundColor = '#ff4d4f'; // Red background
  button.style.color = '#fff'; // White text
  button.style.border = 'none'; // No border
  button.style.padding = '5px 10px'; // Smaller padding for compact size
  button.style.borderRadius = '3px'; // Smaller rounded corners
  button.style.boxShadow = '0 2px 5px rgba(0, 0, 0, 0.15)'; // Subtle shadow
  button.style.fontSize = '12px'; // Smaller font size
  button.style.fontWeight = 'bold'; // Bold text
  button.style.fontFamily = "'Arial', sans-serif"; // Use a nice font family
  button.style.letterSpacing = '0.5px'; // Add some letter spacing
  button.style.textTransform = 'uppercase'; // Transform text to uppercase
  button.style.textShadow = '1px 1px 2px rgba(0, 0, 0, 0.3)'; // Add a subtle text shadow
  button.style.right = '0';

  // Add hover and focus effects
  button.style.transition = 'background-color 0.3s ease, transform 0.3s ease'; // Smooth transitions
  button.onmouseover = () => {
    button.style.backgroundColor = '#d9363e'; // Darker red on hover
    button.style.transform = 'scale(1.05)'; // Slightly larger on hover
  };
  button.onmouseout = () => {
    button.style.backgroundColor = '#ff4d4f'; // Original red color
    button.style.transform = 'scale(1)'; // Reset size
  };
  button.onfocus = () => {
    button.style.outline = 'none'; // Remove default focus outline
    button.style.boxShadow = '0 0 0 3px rgba(255, 77, 79, 0.3)'; // Custom focus shadow
  };
  button.onblur = () => {
    button.style.boxShadow = '0 2px 5px rgba(0, 0, 0, 0.15)'; // Reset shadow
  };

  return button;
};

export const createElementWithTextAndButton = ({
  text,
  onClick,
  subText = '',
  margin = 1,
  deleteButton = true,
}: {
  text: string;
  onClick: () => void;
  subText?: string;
  margin?: number;
  deleteButton?: boolean;
}): HTMLElement => {
  const mainContainer = document.createElement('div');
  const topContainer = document.createElement('div');
  const subContainer = document.createElement('div');
  topContainer.className = 'region-text-content';
  topContainer.style.overflow = 'hidden';
  topContainer.style.zIndex = '900';
  subContainer.className = 'region-sub-content';

  mainContainer.style.position = 'relative';
  subContainer.innerHTML = subText;
  subContainer.style.backgroundColor = '#f0f0f0';
  subContainer.style.margin = `${5 + 50 * margin}px 5px 5px 5px`; // Add margin to the subContainer
  subContainer.style.padding = '5px';
  subContainer.style.whiteSpace = 'nowrap'; // Prevent wrapping
  subContainer.style.overflow = 'hidden'; // Hide overflow
  subContainer.style.textOverflow = 'ellipsis'; // Add ellipsis
  subContainer.style.maxWidth = '90%'; // Set a specific width to subContainer

  topContainer.textContent = text;
  if (deleteButton) {
    topContainer.style.backgroundColor = '#f0f0f0'; // Light gray background
  } else {
    topContainer.style.backgroundColor = '#52c41a'; // Green background
  }
  topContainer.style.display = 'flex';
  topContainer.style.paddingLeft = '5px';
  topContainer.style.alignItems = 'flex-start'; // Align items to the top

  if (deleteButton) {
    mainContainer.appendChild(createHtmlElementButton(onClick));
  } else {
  }

  mainContainer.appendChild(topContainer);
  mainContainer.appendChild(subContainer);

  // Optional: Add some styles to the container
  mainContainer.style.position = 'relative'; // Set position to relative

  return mainContainer;
};

export const fetchAudioBuffer = async (
  audioUrl: string,
  audioContext: AudioContext
): Promise<AudioBuffer> => {
  const response = await fetch(audioUrl, {
    mode: 'cors',
    headers: {
      Range: 'bytes=0-100000', // download only  100000 first octets
    },
  });
  const arrayBuffer = await response.arrayBuffer();
  return audioContext.decodeAudioData(arrayBuffer);
};
