import { useEffect, useState, useRef } from 'react';
import { fetchAudioBuffer } from '../helpers/segments';

export interface UseAudioPlayerProps {
  audioUrl: string;
}

/**
 *
 * Because we want to play with mute/ volume per channel inside an audio this is only possible with this way
 * We need to create audiocontext ...
 */

const useAudioPlayer = ({ audioUrl }: UseAudioPlayerProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioElementRef = useRef<HTMLMediaElement | null>(null);
  const [audioContext] = useState(() => new globalThis.AudioContext());
  const [gainNodes, setGainNodes] = useState<GainNode[]>([]);
  const [audioReady, setAudioReady] = useState(false);
  const [mutedChannels, setMutedChannels] = useState<boolean[]>([]);
  const [soloedChannel, setSoloedChannel] = useState<number | null>(null);
  const [numberOfChannels, setNumberOfChannels] = useState<number>(0);

  useEffect(() => {
    resumeAudioContext(audioContext);
  }, [audioContext]);

  useEffect(() => {
    initializeAudio(audioUrl, audioContext);
  }, [audioUrl, audioContext]);

  const handleLoadedMetadata = () => {
    setAudioReady(true);
  };

  const getNumberOfChannels = async (
    audioUrl: string,
    audioContext: AudioContext
  ): Promise<number> => {
    if (!audioUrl) return 0;
    const audioBuffer = await fetchAudioBuffer(audioUrl, audioContext);
    setNumberOfChannels(audioBuffer.numberOfChannels);
    return audioBuffer.numberOfChannels;
  };

  const initializeAudio = async (
    audioUrl: string,
    audioContext: AudioContext
  ) => {
    const channels = await getNumberOfChannels(audioUrl, audioContext);
    if (!channels) return;
    setMutedChannels(Array(channels).fill(false));

    const audioElement = document.createElement('audio');
    audioElement.crossOrigin = 'anonymous';
    audioElement.id = 'audiodocument';
    const sourceElement = document.createElement('source');

    // sourceElement.src = audioUrl;
    sourceElement.type = 'audio/opus';
    audioElement.appendChild(sourceElement);

    audioElementRef.current = audioElement;
    if (!audioContext) return;
    const track = audioContext.createMediaElementSource(audioElement);
    const channelSplitter = audioContext.createChannelSplitter(channels);
    track.connect(channelSplitter);

    const gainNodesArray = Array.from({ length: channels }, () =>
      audioContext.createGain()
    );
    gainNodesArray.forEach((gainNode, index) => {
      channelSplitter.connect(gainNode, index);
      gainNode.connect(audioContext.destination);
    });

    setGainNodes(gainNodesArray);

    audioElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    audioElement.addEventListener('play', play);
    audioElement.addEventListener('pause', pause);

    return () => {
      audioElement.pause();
      track.disconnect();
      gainNodesArray.forEach((gainNode) => gainNode.disconnect());
      audioElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
    };
  };

  const play = async () => {
    setIsPlaying(true);
    if (audioContext.state === 'suspended') {
      await audioContext.resume();
      audioElementRef.current?.play();
    } else {
      audioElementRef.current?.play();
    }
  };

  const pause = () => {
    setIsPlaying(false);
    audioElementRef.current?.pause();
  };

  const muteChannel = (channelIndex: number) => {
    if (channelIndex < gainNodes.length) {
      gainNodes[channelIndex].gain.setValueAtTime(0, audioContext.currentTime);
      setMutedChannels((prev) => {
        const updated = [...prev];
        updated[channelIndex] = true;
        return updated;
      });
    }

    setSoloedChannel(null);
  };

  const unmuteChannel = (channelIndex: number, volume: number = 1) => {
    if (channelIndex < gainNodes.length) {
      gainNodes[channelIndex].gain.setValueAtTime(
        volume,
        audioContext.currentTime
      );
      setMutedChannels((prev) => {
        const updated = [...prev];
        updated[channelIndex] = false;
        return updated;
      });
    }

    setSoloedChannel(null);
  };

  const soloChannel = (channelIndex: number) => {
    if (channelIndex < gainNodes.length) {
      gainNodes[channelIndex].gain.setValueAtTime(1, audioContext.currentTime);
      gainNodes.forEach((node, index) => {
        if (index !== channelIndex) {
          node.gain.setValueAtTime(0, audioContext.currentTime);
        }
      });
      setMutedChannels((prev) => {
        const updated = [...prev];
        updated.fill(true);
        updated[channelIndex] = false;
        return updated;
      });
      setSoloedChannel(channelIndex);
    }
  };

  const unSoloChannel = (channelIndex: number) => {
    if (channelIndex < gainNodes.length) {
      gainNodes[channelIndex].gain.setValueAtTime(1, audioContext.currentTime);
      gainNodes.forEach((node) => {
        node.gain.setValueAtTime(1, audioContext.currentTime);
      });
      setMutedChannels((prev) => {
        const updated = [...prev];
        updated.fill(false);
        return updated;
      });
      setSoloedChannel(null);
    }
  };

  return {
    play,
    pause,
    isPlaying,
    muteChannel,
    unmuteChannel,
    soloChannel,
    unSoloChannel,
    gainNodes,
    audioDomElement: audioElementRef.current as HTMLMediaElement,
    audioReady,
    numberOfChannels,
    mutedChannels,
    soloedChannel,
  };
};

export default useAudioPlayer;

export const resumeAudioContext = (audioContext: AudioContext) => {
  if (audioContext.state === 'suspended') {
    const resume = () => audioContext.resume();
    document.addEventListener('click', resume, { once: true });
    return () => document.removeEventListener('click', resume);
  }
};
