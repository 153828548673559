import _ from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { Button, Modal } from 'antd';
import { SaveTwoTone } from '@ant-design/icons';

const { confirm } = Modal;

interface SaveAlert {
  onConfirm: () => void;
  autoSave?: boolean;
  autoSaveDelay?: number;
}

export const createDebouncedSave = (
  handleSave: () => void,
  autoSaveDelay: number
) => {
  return _.debounce(() => {
    handleSave();
  }, autoSaveDelay);
};

export const createConfirmDialog = (
  handleSave: () => void,
  setIsModified: (value: boolean) => void
) => {
  return () => {
    confirm({
      title: 'You have unsaved changes. Do you want to save your work?',
      content: 'Your changes will be lost if you don’t save them.',
      onOk() {
        handleSave();
      },
      onCancel() {
        setIsModified(false);
      },
      okText: 'Save',
      cancelText: 'Discard',
    });
  };
};

export const useSaveAlert = ({
  onConfirm,
  autoSave = true,
  autoSaveDelay = 10000,
}: SaveAlert) => {
  const [isModified, setIsModified] = useState(false);

  const handleIsModified = useCallback(() => {
    setIsModified(true);
  }, []);

  const handleSave = useCallback(() => {
    setIsModified(false);
    onConfirm();
  }, [onConfirm]);

  const debouncedSave = useCallback(
    createDebouncedSave(handleSave, autoSaveDelay),
    [handleSave, autoSaveDelay]
  );

  const showConfirm = useCallback(
    createConfirmDialog(handleSave, setIsModified),
    [handleSave]
  );

  useEffect(() => {
    if (isModified && autoSave) {
      debouncedSave();
    }

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isModified) {
        e.preventDefault();
        e.returnValue = '';
        showConfirm();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      debouncedSave.cancel();
    };
  }, [autoSave, debouncedSave, handleSave, isModified, showConfirm]);

  const SaveButton = () => (
    <Button icon={<SaveTwoTone />} disabled={!isModified} onClick={handleSave}>
      Save
    </Button>
  );

  return {
    isModified,
    handleIsModified,
    SaveButton,
    handleSave,
  };
};

export default useSaveAlert;
