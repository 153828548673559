import _, { sortBy } from 'lodash';
import useAudioPlayer from '../hooks/useAudioPlayer';
import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FileState, FinalValidationType } from 'ava-label-types';
import { formatInput } from '../helpers/formater';
import useNotification from '../hooks/useNotification';
import useSpeakerSelect from '../hooks/useSpeakerSelect';
import MultiTrack from '../components/MultiTrack';
import ToggleButton from '../components/ToggleButton';
import { MutedOutlined, SoundOutlined, PlusOutlined } from '@ant-design/icons';
import { useHotkeys } from 'react-hotkeys-hook';
import { Col, Row, Modal, Input, Alert, notification } from 'antd';
import {
  fetchDocumentStateByConversationId,
  updateSegment,
  editSpeakerName,
  addSpeaker,
  functions,
  removeSpeaker,
  blockDocumentToCurrentUser,
} from '../providers/firebase';
import SpeakerTag from '../components/SpeakerTag';
import LargeSpinnerLoader from '../components/LargeSpinnerLoader';
import { httpsCallable } from 'firebase/functions';
import useSaveAlert from '../hooks/useSaveAlert';
import { determineNewSpeakerId, extractNumber } from '../helpers/speaker';
import { useAuth } from '../providers/AuthProvider';

type GetSignedParams = {
  convoId: string;
  finalValidationType: FinalValidationType | null | false; // null is default state, false means document is not validated yet
};

const useGetSignedS3Url = ({
  convoId,
  finalValidationType,
}: GetSignedParams) => {
  const [urlSigned, setUrlSigned] = useState<string>('');
  const [peaks, setPeaks] = useState<number[][]>();

  useEffect(() => {
    if (finalValidationType === null) return;
    httpsCallable<GetSignedParams, { segments: string; peaks: string }>(
      functions,
      'generateSignedS3Url'
    )({ convoId, finalValidationType }).then(
      ({ data: generateFlacSignedFlaS3Url }) => {
        fetch(generateFlacSignedFlaS3Url.peaks)
          .then((response) => {
            if (!response.ok) {
              throw new Error('HTTP error ' + response.status);
            }
            return response.json();
          })
          .then((peaks) => {
            setPeaks(peaks.data);
          })
          .catch((e) => {
            setPeaks([]);
          });
        setUrlSigned(generateFlacSignedFlaS3Url?.segments ?? '');
      }
    );
  }, [finalValidationType]);
  return { urlSigned, setUrlSigned, peaks };
};

const LabelingToolPage = () => {
  const { convoId = '' } = useParams<{ convoId: string }>();
  const auth = useAuth();

  const [isDocumentBlocked, setIsDocumentBlocked] = useState<
    false | string | null
  >(null);

  async function verifyDocument() {
    const { data } = await httpsCallable<
      { convoIds: string[]; checkIfCurrentUserOwnDoc: boolean },
      [{ isBlocked: false | string; id: string }]
    >(
      functions,
      'checkAreDocumentBlocked'
    )({ convoIds: [convoId], checkIfCurrentUserOwnDoc: true });
    const { isBlocked } = data[0];

    if (isBlocked === null) {
      await httpsCallable<{ convoId: string }, boolean>(
        functions,
        'ingestSegmentedAIResultsInFirebase'
      )({ convoId });
      setIsDocumentBlocked(false);
    }
    if (isBlocked) {
      setIsDocumentBlocked(isBlocked);
    }

    // setIsDocumentBlocked(isBlocked)
    if (!isBlocked) {
      blockDocumentToCurrentUser(convoId);
      setIsDocumentBlocked(false);
    }
  }

  useEffect(() => {
    if (!convoId || !auth.user) return;
    verifyDocument();
  }, []);

  if (isDocumentBlocked === null) {
    return <LargeSpinnerLoader />;
  }

  if (isDocumentBlocked) {
    return (
      <Alert
        message={`The document is blocked, because it's labeling by
                ${isDocumentBlocked}`}
        type="error"
      ></Alert>
    );
  }

  return (
    <div>
      <LabelingToolPageContent convoId={convoId} />
    </div>
  );
};

interface LabelingToolPageContentProps {
  convoId: string;
}

const LabelingToolPageContent = ({
  convoId = '',
}: LabelingToolPageContentProps) => {
  const [isReady, setIsReady] = useState(false);
  const [addOrUpdateSpeaker, setAddOrUpdateSpeaker] = useState(false);
  const [speakerTextArea, setSpeakerTextArea] = useState('');
  const [finalValidationType, setFinalValidationType] = useState<
    FinalValidationType | null | false
  >(null);
  const [showTransparentHoverlayout, setShowTransparentHoverlayout] = useState<
    false | null
  >(null);

  const setFinalValidationTypeHandler = (
    finalValidationType: FinalValidationType | null
  ) => {
    setFinalValidationType(finalValidationType);
  };

  // TODO check with empty element at basic state
  const { urlSigned, peaks } = useGetSignedS3Url({
    convoId,
    finalValidationType,
  });
  const {
    play,
    pause,
    isPlaying,
    muteChannel,
    unmuteChannel,
    soloChannel,
    unSoloChannel,
    mutedChannels,
    numberOfChannels,
    soloedChannel,
    audioDomElement,
  } = useAudioPlayer({
    audioUrl: urlSigned, // pass audioUrl
  });
  const [labels, setLabels] = useState<FileState>({
    speakers: {},
    segments: [],
  });

  const { isModified, handleIsModified, handleSave } = useSaveAlert({
    onConfirm: () => {},
  });

  useEffect(() => {
    if (!convoId) return;

    const fetchLabels = async () => {
      try {
        const { segments, speakers, final_validation_type } =
          await fetchDocumentStateByConversationId(convoId);
        if (!speakers || !segments) return;
        const { segments: segmentsOutput, speakers: speakersOutput } =
          formatInput({ speakers, segments });
        setLabels({
          segments: segmentsOutput,
          speakers: speakersOutput,
        });
        setFinalValidationType(final_validation_type ?? false);
      } catch (error) {
        console.error('Error fetching labels', error);
      }
    };

    fetchLabels().then(() => {
      setIsReady(true);
    });
  }, [convoId]);

  const sortedSpeakers = useMemo(
    () =>
      sortBy(Object.entries(labels.speakers), ([, value]) =>
        extractNumber(value.name)
      ),
    [labels.speakers]
  );

  const { openNotification, contextHolder } = useNotification();

  const { handleSpeakerSelect, selectedSpeaker } = useSpeakerSelect(
    labels.speakers
  );

  const {
    Waveform,
    RegionController,
    zoomIn,
    zoomOut,
    updateRegionSpeakerName,
  } = MultiTrack({
    urlSigned,
    convoId,
    selectedSpeaker,
    speakers: labels.speakers,
    regionsParams: labels.segments,
    pause,
    handleUpdateSegment: updateSegment,
    isReady,
    handleSave,
    handleIsModified,
    isModified,
    setFinalValidationTypeHandler,
    audioDomElement,
    numberOfChannels,
    peaks,
  });

  useHotkeys('space', (event) => {
    event.preventDefault();
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  });

  useHotkeys('Z+up', (event) => {
    event.preventDefault();
    zoomIn();
  });
  useHotkeys('Z+down', (event) => {
    event.preventDefault();
    zoomOut();
  });

  //FIXME: This is not working
  // useHotkeys(Object.keys(labels.speakers), (keyboardEvent) =>
  //   handleSpeakerSelect(keyboardEvent.key)
  // );

  useHotkeys(['ctrl+s', 'meta+s'], (event) => {
    event.preventDefault();
    openNotification({ title: 'Saved' });
  });

  useHotkeys(['x'], () => {
    openNotification({ title: 'Reject' });
  });

  useHotkeys(['i'], () => {
    openNotification({ title: 'Ignore' });
  });

  useHotkeys(['backspace', 'del'], () => {
    openNotification({ title: 'Undo' });
  });

  if (!isReady || !peaks) {
    return <LargeSpinnerLoader />;
  }

  return (
    <>
      {selectedSpeaker}
      <div id="dashboard" className={finalValidationType ? 'blockEvents' : ''}>
        {contextHolder}
        <Modal
          style={{ width: '90%' }}
          open={addOrUpdateSpeaker}
          onClose={() => setAddOrUpdateSpeaker(false)}
          onOk={() => {
            handleIsModified();
            if (!convoId) {
              console.error('No convoId');
              return;
            }
            const oldSpeakerName = labels.speakers[selectedSpeaker].name;
            //check if sspeaker name is not empty and not already present in other speakers
            if (
              speakerTextArea === '' ||
              Object.values(labels.speakers).some(
                (speaker) => speaker.name === speakerTextArea
              )
            ) {
              setSpeakerTextArea(oldSpeakerName);
              alert('Speaker name cannot be empty or already present');
              return;
            }
            editSpeakerName(convoId, selectedSpeaker, speakerTextArea).then(
              handleSave
            );
            labels.speakers[selectedSpeaker].name = speakerTextArea;
            updateRegionSpeakerName(oldSpeakerName, speakerTextArea);
            setAddOrUpdateSpeaker(false);
          }}
          onCancel={() => {
            setAddOrUpdateSpeaker(false);
          }}
        >
          <Input.TextArea
            style={{ width: '90%' }}
            defaultValue={labels.speakers[selectedSpeaker]?.name}
            value={speakerTextArea}
            onChange={(e) => {
              setSpeakerTextArea(e.target.value);
            }}
          />
        </Modal>
        <Row gutter={10}>
          <Col span={4}></Col>
          <Col span={16} style={{ height: 'auto' }}>
            <Row
              justify="space-evenly"
              align="middle"
              style={{ marginBottom: 20 }}
            >
              <div>
                <SpeakerTag
                  onClick={() => {
                    handleIsModified();
                    if (!convoId) {
                      console.error('No convoId');
                      return;
                    }
                    const speakerKeys = Object.keys(labels.speakers);
                    const newSpeakerId = determineNewSpeakerId(speakerKeys);
                    const newSpeakerName = newSpeakerId;
                    setLabels({
                      ...labels,
                      speakers: {
                        ...labels.speakers,
                        [newSpeakerId]: {
                          name: newSpeakerName,
                        },
                      },
                    });
                    addSpeaker(convoId, newSpeakerId, newSpeakerName).then(
                      handleSave
                    );
                  }}
                >
                  <PlusOutlined />
                </SpeakerTag>
                {sortedSpeakers.map(([keyId, speaker]) => (
                  <SpeakerTag
                    speakerId={keyId}
                    selected={selectedSpeaker === keyId}
                    key={keyId}
                    onClick={() => handleSpeakerSelect(keyId)}
                    onDoubleClick={() => {
                      handleSpeakerSelect(keyId);
                      setSpeakerTextArea(speaker.name);
                      setAddOrUpdateSpeaker(true);
                    }}
                    onDelete={() => {
                      if (selectedSpeaker === keyId) {
                        notification.error({
                          message: 'Cannot delete selected speaker',
                        });
                        return;
                      }

                      handleIsModified();
                      updateRegionSpeakerName(speaker.name);
                      setLabels({
                        ...labels,
                        speakers: _.omit(labels.speakers, keyId),
                      });

                      removeSpeaker(convoId, keyId).then(() => {
                        setTimeout(() => {
                          const sortedSpeakersUpdated = sortBy(
                            Object.entries(labels.speakers),
                            ([, value]) => extractNumber(value.name)
                          );
                          handleSpeakerSelect(
                            sortedSpeakersUpdated[
                              sortedSpeakersUpdated.length - 1
                            ][0]
                          );
                        }, 250);
                        handleSave();
                      });
                    }}
                  >
                    {speaker.name}
                  </SpeakerTag>
                ))}
              </div>
            </Row>
          </Col>
          <Col span={4}></Col>
        </Row>
        <Row gutter={10}>
          <Col span={4} style={{ marginTop: 0 }}>
            <Row gutter={16} style={{ marginRight: 0, height: '100%' }}>
              <Col
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                }}
              >
                {numberOfChannels >= 2 &&
                  _.range(numberOfChannels).map((channelId, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <ToggleButton
                        callback={() => muteChannel(channelId)}
                        reverseCallback={() => unmuteChannel(channelId)}
                        enabled={!mutedChannels[channelId]}
                        extraStyle={{ marginRight: 5 }}
                      >
                        {mutedChannels[channelId] ? (
                          <MutedOutlined />
                        ) : (
                          <SoundOutlined />
                        )}
                      </ToggleButton>
                      <ToggleButton
                        callback={() => unSoloChannel(channelId)}
                        reverseCallback={() => soloChannel(channelId)}
                        enabled={soloedChannel === channelId}
                      >
                        Solo
                      </ToggleButton>
                    </div>
                  ))}
              </Col>
            </Row>
          </Col>
          {!peaks ? (
            <Col span={16}>
              <LargeSpinnerLoader />
            </Col>
          ) : (
            <Col span={16}>{Waveform()}</Col>
          )}
          <Col span={4}>{RegionController()}</Col>
        </Row>
      </div>
      {/* The div below is here to inform that the document is finished (validated/rejected) */}
      <div
        style={{
          visibility:
            showTransparentHoverlayout !== false && Boolean(finalValidationType)
              ? 'visible'
              : 'hidden',
          background:
            finalValidationType === 'VALIDATED' ? '#A7E0E0' : '#E8AABE',
          opacity: 0.75,
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 2,
        }}
      ></div>
      <Alert
        message={String(finalValidationType)}
        description={`This document is ${String(finalValidationType).toLowerCase()} you cannot modify it`}
        type={finalValidationType === 'VALIDATED' ? 'success' : 'error'}
        showIcon
        closable
        style={{
          visibility: Boolean(finalValidationType) ? 'visible' : 'hidden',
          width: '50vw',
          height: '100px',
          position: 'fixed',
          top: 'calc(50% - 100px)',
          left: '25%',
          zIndex: 3,
        }}
        onClick={() => setShowTransparentHoverlayout(false)}
      />
    </>
  );
};

export default LabelingToolPage;
