import { SegmentsDurations } from 'ava-label-types';
import { httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from 'react';
import { functions } from '../providers/firebase';

const Metrics = () => {
  const [totalDuration, setTotalDuration] = useState<number>(0);
  const [totalDurationPerScribe, setTotalDurationPerScribe] =
    useState<SegmentsDurations>({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchFunction = async () => {
      const response = await httpsCallable<void, SegmentsDurations>(
        functions,
        'getSegmentsDurations'
      )();
      setTotalDurationPerScribe(response.data);
      const computedDuration = Object.keys(response.data).reduce((acc, key) => {
        if (key && response.data[key]?.time_validated) {
          return acc + response.data[key].time_validated;
        }
        return acc;
      }, 0);
      setTotalDuration(computedDuration);

      setIsLoading(false);
    };

    fetchFunction();
  }, []);
  return (
    <div>
      <h1>Metrics</h1>
      {/*convert to HH:MM:SS*/}
      {isLoading ? (
        'Loading...'
      ) : (
        <>
          <div>
            <strong>Total duration:</strong>{' '}
            {totalDuration
              ? new Date(totalDuration * 1000).toISOString().substr(11, 8)
              : '00:00:00'}
          </div>

          <div>
            {Object.keys(totalDurationPerScribe).map((scribe) => (
              <div key={scribe}>
                <strong>{totalDurationPerScribe[scribe].name}: </strong>
                {totalDurationPerScribe[scribe]?.time_validated
                  ? new Date(
                      totalDurationPerScribe[scribe].time_validated * 1000
                    )
                      .toISOString()
                      .substr(11, 8)
                  : '00:00:00'}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Metrics;
