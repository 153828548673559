import _ from 'lodash';

// This function avoid creating the speaker_id according to the length of the speakers array
export const determineNewSpeakerId = (speakers: string[]) => {
  // Extract numerical parts of the speaker IDs using Lodash's map and parseInt
  const ids = _.map(speakers, (speaker) => _.parseInt(speaker.split('_')[1]));

  // Sort the IDs
  const sortedIds = _.sortBy(ids);

  // Find the first missing ID using Lodash's findIndex
  const missingIdIndex = _.findIndex(
    sortedIds,
    (id, index) => id !== index + 1
  );

  // If a missing ID is found, return it, otherwise return the next highest ID
  const newId =
    missingIdIndex !== -1 ? missingIdIndex + 1 : sortedIds.length + 1;

  return `speaker_${newId}`;
};

export function extractNumber(str: string) {
  const match = str.match(/\d+/);
  return match ? parseInt(match[0], 10) : 0;
}
