import { speakerColors } from './constants';
import _ from 'lodash';

import { LabelingInput, SegmentUI } from 'ava-label-types';

export const UNDEFINED_SPEAKER_ID = 'UNDEFINED';
export const UNDEFINED_SPEAKER_COLOR = '#0000004D';

export const formatInput = (json: LabelingInput) => {
  const segments: SegmentUI[] = json?.segments?.map((segment) => {
    const speakerExists = _.has(json.speakers[segment.speaker_id], 'name');

    return {
      id: segment.id,
      start: segment.start_time,
      end: segment.end_time,
      validated: Boolean(segment.validated_by),
      // color: speaker ? speaker.color : '#0000004D', // Default to black if no speaker found
      color: speakerExists
        ? speakerColors[segment.speaker_id]
        : UNDEFINED_SPEAKER_COLOR,
      speakerId: segment.speaker_id,
      content: speakerExists
        ? json.speakers[segment.speaker_id].name
        : UNDEFINED_SPEAKER_ID,
      subContent: segment.transcript,
    };
  });
  return {
    speakers: json.speakers,
    segments,
  };
};

export const changeColorOpacity = (rgba: string, newOpacity: number) => {
  if (!rgba || !newOpacity) return rgba;
  // Split the rgba string by commas
  const parts = rgba.split(',');

  // Update the alpha (opacity) part
  parts[3] = ` ${newOpacity})`;

  // Join the parts back into a single string
  return parts.join(',');
};

export const splitRegionContent = (content: string): string[] => {
  return content.split('|');
};
