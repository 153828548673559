import React from 'react';
import { notification } from 'antd';
import { NotificationPlacement } from 'antd/es/notification/interface';

interface Notification {
  title: React.ReactNode;
  placement?: NotificationPlacement;
  description?: React.ReactNode;
}

const useNotification = () => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = ({
    title = '',
    placement = 'topRight',
    description = '',
  }: Notification) => {
    api.info({ message: title, placement, description });
  };

  return { openNotification, contextHolder };
};

export default useNotification;
