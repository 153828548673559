/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ReactNode, useState } from 'react';
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Menu, Drawer, Button, Layout, Breadcrumb } from 'antd';
import {
  MenuOutlined,
  LogoutOutlined,
  BarChartOutlined,
  LoginOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import { useAuth } from '../providers/AuthProvider';
import LargeSpinnerLoader from './LargeSpinnerLoader';

const { Header, Content } = Layout;

interface ProtectedRouteProps {
  children?: ReactNode;
}

const StyledHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

const HeaderTitle = styled.div`
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
`;

const BackButton = styled(Button)`
  color: #fff;
  margin-right: 10px;
`;

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const auth = useAuth();
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { convoId } = useParams<{ convoId: string }>();

  if (auth.isLoggingIn) {
    return <LargeSpinnerLoader />;
  }

  if (!auth.isUserScribe) {
    return <Navigate to="/" />;
  }

  const toggleDrawer = () => {
    setVisible(!visible);
  };

  const handleMenuClick = ({ key }: { key: string }) => {
    if (key === 'logout') {
      auth.logout();
    } else if (key === 'login') {
      auth.login();
    }
    setVisible(false);
  };

  const getHeaderTitle = (pathname: string, convoId?: string) => {
    if (pathname === '/home') {
      return 'Home';
    }
    if (pathname === '/metrics') {
      return 'Metrics';
    }
    return convoId ? `Dashboard - ${convoId}` : 'Dashboard';
  };

  const handleBackButtonClick = (pathname: string) => {
    if (pathname.startsWith('/dashboard')) {
      globalThis.location.href = '/';
    }
    if (pathname.startsWith('/metrics')) {
      navigate('/home');
    }
  };

  const headerTitle = getHeaderTitle(location.pathname, convoId);

  return (
    <Layout>
      <StyledHeader>
        <Button
          type="text"
          icon={<MenuOutlined className="menu-button" />}
          onClick={toggleDrawer}
        />
        <HeaderTitle>
          {location.pathname !== '/home' && (
            <BackButton
              type="text"
              onClick={() => handleBackButtonClick(location.pathname)}
              color="#fff"
            >
              <ArrowLeftOutlined />
            </BackButton>
          )}
          {headerTitle}
        </HeaderTitle>
      </StyledHeader>
      <Drawer
        title="Menu"
        placement="left"
        closable
        onClose={toggleDrawer}
        visible={visible}
        bodyStyle={{ padding: 0 }}
      >
        <Menu mode="inline" onClick={handleMenuClick}>
          {/* We can show this if needed later */}
          {/* <Menu.Item key="login" icon={<FileOutlined />}>
            <a href="originalJsonUrl" target="_blank" rel="noreferrer">
              Original JSON segmented from AI
            </a>
          </Menu.Item>
          <Menu.Item key="login" icon={<FileTextOutlined />}>
            <a href="finalJsonUrl" target="_blank" rel="noreferrer">
              Current Segmented JSON from Scribe
            </a>
          </Menu.Item> */}
          <Menu.Item key="metrics" icon={<BarChartOutlined />}>
            <a onClick={() => navigate('/metrics')}>Metrics</a>
          </Menu.Item>
          {auth.isAuthenticated ? (
            <Menu.Item key="logout" icon={<LogoutOutlined />}>
              Logout
            </Menu.Item>
          ) : (
            <Menu.Item key="login" icon={<LoginOutlined />}>
              Login
            </Menu.Item>
          )}
        </Menu>
      </Drawer>
      <Content
        css={css`
          padding: 20px;
        `}
      >
        <Breadcrumb
          css={css`
            margin-bottom: 20px;
          `}
        >
          <Breadcrumb.Item>
            <a onClick={() => navigate('/')}>Home</a>
          </Breadcrumb.Item>
          {location.pathname.startsWith('/dashboard') && (
            <Breadcrumb.Item>
              {convoId ? `Dashboard - ${convoId}` : 'Dashboard'}
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
        {children}
        <Outlet />
      </Content>
    </Layout>
  );
};

export default ProtectedRoute;
