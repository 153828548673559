import React from 'react';
import _ from 'lodash';
import { Tag, TagProps, Popconfirm } from 'antd';
import { speakerColors } from '../helpers/constants';
import { CloseOutlined } from '@ant-design/icons';

interface SpeakerTagProps extends TagProps {
  speakerId?: string;
  selected?: boolean;
  onDelete?: () => void;
}

const SpeakerTag = ({
  speakerId = '',
  selected = false,
  onDelete,
  ...props
}: SpeakerTagProps) => {
  const speakerColor: string = _.get(speakerColors, speakerId);

  return (
    <Tag
      {...props}
      color={speakerColor}
      className={`speaker-tag ${selected ? 'speaker-tag-selected' : 'speaker-tag-unselected'}`}
      bordered={true}
      closable={!!onDelete}
      onClose={(e) => {
        e.preventDefault();
      }}
      closeIcon={
        <Popconfirm
          title="Are you sure you want to delete this tag?"
          onConfirm={(e) => {
            e?.stopPropagation();
            e?.preventDefault();
            onDelete?.();
          }}
          okText="Yes"
          cancelText="No"
        >
          <CloseOutlined className="speaker-tag-close-icon" />
        </Popconfirm>
      }
    >
      {props.children}
    </Tag>
  );
};

export default SpeakerTag;
