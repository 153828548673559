/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { Button, Card, Spin, Typography } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';
import { useAuth } from '../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;

const loginContainerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
`;

const StyledCard = styled(Card)`
  width: 100%;
  max-width: 400px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const LoginPage = () => {
  const { isAuthenticated, login, logout, isUserScribe, isLoggingIn } =
    useAuth();
  const navigate = useNavigate();

  const navigateToHomePage = () => {
    login();
    navigate('/home');
  };

  return (
    <div css={loginContainerStyle}>
      <StyledCard>
        <Title level={2}>Welcome to the Dashboard</Title>
        <Spin spinning={isLoggingIn}>
          <Button
            type="primary"
            icon={<GoogleOutlined />}
            size="large"
            onClick={isAuthenticated ? logout : navigateToHomePage}
            block
          >
            {isAuthenticated ? 'Logout' : 'Login with Google'}
          </Button>
        </Spin>
        <Text type="danger">
          {!isUserScribe &&
            isAuthenticated &&
            !isLoggingIn &&
            'You are not a scribe, you cannot access the dashboard'}
        </Text>
      </StyledCard>
    </div>
  );
};

export default LoginPage;
