import React, { forwardRef, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';

interface ButtonProps {
  onClick: () => void;
}

const Button: React.FC<ButtonProps> = ({ onClick }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const buttonElement = buttonRef.current;

    if (buttonElement) {
      buttonElement.addEventListener('click', handleClick);

      return () => {
        buttonElement.removeEventListener('click', handleClick);
      };
    }

    function handleClick(event: MouseEvent) {
      event.stopPropagation();
      onClick();
    }
  }, [onClick]);

  return (
    <button
      ref={buttonRef}
      style={{
        marginLeft: 'auto',
        cursor: 'pointer',
        position: 'absolute',
        zIndex: 10000,
        backgroundColor: '#ff4d4f',
        color: '#fff',
        border: 'none',
        padding: '5px 10px',
        borderRadius: '3px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
        fontSize: '13px',
        fontWeight: 'bold',
        fontFamily: "'Arial', sans-serif",
        letterSpacing: '0.5px',
        textTransform: 'uppercase',
        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
        right: 0,
        transition: 'background-color 0.3s ease, transform 0.3s ease',
      }}
      onMouseOver={(e) => {
        e.currentTarget.style.backgroundColor = '#d9363e';
        e.currentTarget.style.transform = 'scale(1.05)';
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.backgroundColor = '#ff4d4f';
        e.currentTarget.style.transform = 'scale(1)';
      }}
      onFocus={(e) => {
        e.currentTarget.style.outline = 'none';
        e.currentTarget.style.boxShadow = '0 0 0 3px rgba(255, 77, 79, 0.3)';
      }}
      onBlur={(e) => {
        e.currentTarget.style.boxShadow = '0 2px 5px rgba(0, 0, 0, 0.15)';
      }}
    >
      X
    </button>
  );
};

interface ElementWithTextAndButtonProps {
  text: string;
  subText?: string;
  margin?: number;
  deleteButton?: boolean;
  ref: React.Ref<HTMLElement>;
  onClick: () => void;
  onMount?: () => void;
}

// eslint-disable-next-line react/display-name
export const ElementWithTextAndButton = forwardRef<
  HTMLElement,
  ElementWithTextAndButtonProps
>(({ text, subText, margin, deleteButton, onClick, onMount }, ref) => {
  useEffect(() => {
    if (onMount) {
      onMount();
    }
  }, [onMount]);

  return (
    <section
      ref={ref}
      style={{ position: 'relative' }}
      className="segmentContainer"
    >
      <div>{deleteButton && <Button onClick={onClick} />}</div>

      <div
        className="region-text-content"
        style={{
          overflow: 'hidden',
          zIndex: 900,
          backgroundColor: deleteButton ? '#f0f0f0' : '#52c41a',
          display: 'flex',
          paddingLeft: '5px',
          alignItems: 'flex-start',
        }}
      >
        <div>{text}</div>
      </div>
      <div
        className="region-sub-content"
        style={{
          backgroundColor: '#f0f0f0',
          margin: `${5 + 50 * (margin ?? 1)}px 5px 5px 5px`,
          padding: '5px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '90%',
        }}
      >
        {subText}
      </div>
    </section>
  );
});

export const createProgrammaticElementWithTextAndButton = ({
  text,
  onClick,
  subText = '',
  margin = 1,
  deleteButton = true,
}: {
  text: string;
  onClick: () => void;
  subText?: string;
  margin?: number;
  deleteButton?: boolean;
}) => {
  return new Promise<React.RefObject<HTMLElement>>((resolve) => {
    const container = document.createElement('div');
    document.body.appendChild(container);

    const elementRef = React.createRef<HTMLElement>();

    const handleMount = () => {
      resolve(elementRef);
    };

    ReactDOM.createRoot(container).render(
      <ElementWithTextAndButton
        onClick={onClick}
        text={text}
        subText={subText}
        margin={margin}
        deleteButton={deleteButton}
        ref={elementRef}
        onMount={handleMount}
      />
    );
  });
};
