import { initializeApp } from 'firebase/app';
import {
  arrayUnion,
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import type { FinalValidationType, Segment } from 'ava-label-types';
import { SegmentInput } from 'ava-label-types';
import { finalDataConverter, userDataConverter } from './converters';

const DELAYED_UPDATE_TIMEOUT = 2500; // we need that to have bulk update but all other operation must be performed with the same timeout even if not grouped

const firebaseConfigStage = {
  apiKey: 'AIzaSyAGbQMVzNWxMpMjZXcOck9IrBiJs0vkdnU',
  authDomain: 'ava-app-dev-92f7f.firebaseapp.com',
  databaseURL: 'https://ava-app-dev-92f7f-default-rtdb.firebaseio.com',
  projectId: 'ava-app-dev-92f7f',
  storageBucket: 'ava-app-dev-92f7f.appspot.com',
  appId: '1:316081949771:android:f0d7039c0f46b85f12d1ce',
};

const firebaseConfigProd = {
  apiKey: 'AIzaSyCtBD2GuK81YMG6gmGm32iVmnNMsFNbc9c',
  authDomain: 'ava-product.firebaseapp.com',
  databaseURL: 'https://ava-product.firebaseio.com',
  projectId: 'ava-product',
  storageBucket: 'ava-product.appspot.com',
  appId: '1:47114113593:android:4d3d36565d9464cd',
};

export const app = initializeApp(
  process.env.REACT_APP_ENV === 'production'
    ? firebaseConfigProd
    : firebaseConfigStage
);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);

if (process.env.REACT_APP_ENV === 'development') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export const fetchUserData = async (uid: string) => {
  const userQuery = query(
    collection(db, 'users').withConverter(userDataConverter),
    where('firebaseUID', '==', uid)
  );
  const querySnapshot = await getDocs(userQuery);
  if (querySnapshot.empty) {
    console.error('No document found for this user');
    return null;
  }

  // Assuming we are interested in the first document only
  const userData = querySnapshot.docs[0]?.data();
  return userData;
};

export const fetchDocumentStateByConversationId = async (
  conversationId: string
) => {
  const docRef = doc(db, 'ava-label', conversationId).withConverter(
    finalDataConverter
  );
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    // TODO Here we need to create a new document for this conversation from the json and put the loading state to true
    throw new Error('No document found for this conversation');
  }

  // Assuming the document contains the labels in the expected structure
  return docSnap.data();
};

export const getDocumentsValidation = async (conversationsId: string[]) => {
  const docsCollection = collection(db, 'ava-label').withConverter(
    finalDataConverter
  );

  // Split the conversationsId array into chunks of 10 to avoid Firestore 'in' query limit
  const chunkSize = 10;
  const validationByDoc: FinalValidationType[] = [];

  for (let i = 0; i < conversationsId.length; i += chunkSize) {
    const chunk = conversationsId.slice(i, i + chunkSize);

    const q = query(docsCollection, where('__name__', 'in', chunk));
    const docSnap = await getDocs(q);

    // Create a map for quick lookup of fetched documents
    const fetchedDocsMap = new Map<string, FinalValidationType>();
    docSnap.forEach((doc) => {
      const data = doc.data();
      fetchedDocsMap.set(doc.id, data.final_validation_type);
    });

    // Match the order of requested conversation IDs and handle missing documents
    chunk.forEach((id) => {
      if (fetchedDocsMap.has(id)) {
        validationByDoc.push(fetchedDocsMap.get(id)!);
      } else {
        validationByDoc.push('VALIDATED'); // Default value if document does not exist
      }
    });
  }

  return validationByDoc;
};

// Document is automatically unblocked for current user if limit date has expired and blocked for other user
export async function blockDocumentToCurrentUser(conversationId: string) {
  if (!auth.currentUser?.uid) {
    return;
  }
  const docRef = doc(db, 'ava-label', conversationId).withConverter(
    finalDataConverter
  );
  await updateDoc(docRef, {
    claim: {
      limit_date: serverTimestamp(), // firestore timestamp function call
      user: auth.currentUser?.uid,
    },
  });
  const justBefore1h = 1000 * 60 * 59;
  // re-validated we still handle the doc if scribe still on the page at 59mn
  setTimeout(() => blockDocumentToCurrentUser(conversationId), justBefore1h);
}

// Function to create a new segment
export const createSegment = async (
  conversationId: string,
  newSegment: SegmentInput
) => {
  try {
    const docRef = doc(db, 'ava-label', conversationId).withConverter(
      finalDataConverter
    );
    await delay(DELAYED_UPDATE_TIMEOUT);
    await updateDoc(docRef, {
      segments: arrayUnion(newSegment),
    });
  } catch (error) {
    console.error('Error adding new segment: ', error);
  }
};

// Function to delete a segment by ID
export const deleteSegment = async (
  conversationId: string,
  segmentId: string
) => {
  try {
    const docRef = doc(db, 'ava-label', conversationId).withConverter(
      finalDataConverter
    );
    // Fetch the current document data
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      const updatedSegments = data.segments.filter(
        (segment) => segment.id !== segmentId
      );
      await delay(DELAYED_UPDATE_TIMEOUT);
      await updateDoc(docRef, { segments: updatedSegments });
    }
  } catch (error) {
    console.error('Error deleting segment: ', error);
  }
};

type SegmentManipulation =
  | 'ADD'
  | 'REMOVE'
  | 'VALIDATE'
  | 'UNVALIDATE'
  | 'METADATA';
let debounceTimer: NodeJS.Timeout | null = null;
const updatesBuffer: Map<SegmentManipulation, Partial<SegmentInput>[]> =
  new Map();
// Function to update a segment
export const updateSegment = async (
  type: SegmentManipulation,
  conversationId: string,
  updatedValues: Partial<SegmentInput>[]
) => {
  updatesBuffer.set(type, [
    ...(updatesBuffer.get(type) ?? []),
    ...updatedValues,
  ]);
  if (debounceTimer) {
    clearTimeout(debounceTimer);
  }
  debounceTimer = setTimeout(async () => {
    const docRef = doc(db, 'ava-label', conversationId).withConverter(
      finalDataConverter
    );
    // Fetch the current document data
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) return;
    const data = docSnap.data();

    updatesBuffer.forEach((values, action) => {
      if (action === 'METADATA') {
        values.forEach((segment) => {
          const indexOfSegment = data.segments.findIndex(
            (s) => s.id === segment.id
          );
          data.segments[indexOfSegment] = {
            ...data.segments[indexOfSegment],
            ...segment,
          };
        });
      }
      if (action === 'ADD') {
        data.segments.push(...(values as Segment[]));
      }
      if (action === 'REMOVE') {
        data.segments = data.segments.filter(
          (segment) => !values.some((v) => v.id === segment.id)
        );
      }
      if (action === 'VALIDATE' || action === 'UNVALIDATE') {
        data.segments = data.segments.map((segment) => {
          if (values.some((v) => v.id === segment.id)) {
            const updatedSegment = {
              ...segment,
              validated_by:
                action === 'VALIDATE' ? auth.currentUser?.uid : undefined,
              validated_time: action === 'VALIDATE' ? Date.now() : undefined,
            };
            if (action === 'UNVALIDATE') {
              delete updatedSegment.validated_by;
              delete updatedSegment.validated_time;
            }
            return updatedSegment;
          }
          return segment;
        });
      }
    });

    await updateDoc(docRef, { segments: data.segments });
    // remove the debounce timer and clear the buffer
    debounceTimer = null;
    updatesBuffer.clear();
  }, DELAYED_UPDATE_TIMEOUT);
};

// Function to add a speaker
export const addSpeaker = async (
  conversationId: string,
  speakerId: string,
  speakerName: string
) => {
  try {
    const docRef = doc(db, 'ava-label', conversationId).withConverter(
      finalDataConverter
    );
    const speakerField = `speakers.${speakerId}`;

    await updateDoc(docRef, {
      [speakerField]: { name: speakerName },
    });
  } catch (error) {
    console.error('Error adding speaker: ', error);
  }
};

// Function to edit a speaker's name
export const editSpeakerName = async (
  conversationId: string,
  speakerId: string,
  newSpeakerName: string
) => {
  try {
    const docRef = doc(db, 'ava-label', conversationId);
    const speakerField = `speakers.${speakerId}.name`;

    await updateDoc(docRef, {
      [speakerField]: newSpeakerName,
    });
  } catch (error) {
    console.error('Error updating speaker name: ', error);
  }
};

export const removeSpeaker = async (
  conversationId: string,
  speakerId: string
) => {
  try {
    const docRef = doc(db, 'ava-label', conversationId);
    const speakerField = `speakers.${speakerId}`;

    await updateDoc(docRef, {
      [speakerField]: deleteField(),
    });
  } catch (error) {
    console.error('Error removing speaker: ', error);
  }
};

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
