import React from 'react';
import { Spin } from 'antd';

interface LargeSpinnerLoaderProps {
  height?: string;
}

const LargeSpinnerLoader = ({ height = '100vh' }: LargeSpinnerLoaderProps) => {
  return (
    <div
      className="large-spinner-loader"
      style={{
        height,
      }}
    >
      <Spin size="large" />
    </div>
  );
};

export default LargeSpinnerLoader;
