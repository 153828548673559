import { useEffect, useState } from 'react';
import _ from 'lodash';
import type { SpeakerSelect } from 'ava-label-types';

const selectFirstSpeaker = (
  props: SpeakerSelect,
  selectedSpeaker: string,
  setSelectedSpeaker: (speakerId: string) => void
) => {
  const arrOfProps = Object.keys(props);
  if (selectedSpeaker === 'default' && arrOfProps.length) {
    setSelectedSpeaker(arrOfProps[0]);
  }
};

const useSpeakerSelect = (props: SpeakerSelect) => {
  const [speakers, setSpeakers] = useState<SpeakerSelect>(props);
  const [selectedSpeaker, setSelectedSpeaker] = useState<string>('default'); // this represents the selected speakerid

  useEffect(() => {
    selectFirstSpeaker(props, selectedSpeaker, setSelectedSpeaker);
  }, [props, selectedSpeaker]);

  const addSpeaker = (
    speakerId: string,
    speaker: { name: string; color?: string }
  ) => {
    setSpeakers((prevSpeakers) => ({
      ...prevSpeakers,
      [speakerId]: speaker,
    }));
  };

  const removeSpeaker = (keyId: string) => {
    setSpeakers((prevSpeakers) => {
      const omitedSpeaker = _.omit(prevSpeakers, keyId);
      return omitedSpeaker;
    });
  };

  const handleSpeakerSelect = (keyId: string) => {
    setSelectedSpeaker(keyId);
  };

  return {
    handleSpeakerSelect,
    selectedSpeaker,
    addSpeaker,
    removeSpeaker,
    speakers,
  };
};

export default useSpeakerSelect;
