import React from 'react';
import { Button, ButtonProps } from 'antd';

interface ToggleButtonProps extends ButtonProps {
  children?: React.ReactNode;
  callback?: () => void;
  reverseCallback?: () => void;
  enabled?: boolean;
  extraStyle?: React.CSSProperties;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  children,
  callback = () => {},
  reverseCallback = () => {},
  enabled = true,
  extraStyle = {},
  ...props
}) => {
  const handleClick = () => {
    if (!enabled) {
      reverseCallback();
    } else {
      callback();
    }
  };

  return (
<Button
      onClick={handleClick}
      type={enabled ? 'primary' : 'default'}
      className="toggle-button"
      style={extraStyle}
      {...props}
    >
      {children}
    </Button>
  );
};

export default ToggleButton;
