import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
//Providers
import { AuthProvider } from './providers/AuthProvider';
import { useAuth } from './providers/AuthProvider';
//Pages
import LabelingPage from './pages/Labeling';
import HomePage from './pages/Home';
import LoginPage from './pages/Login';
//Components
import { Layout } from 'antd';
import ProtectedRoute from './components/ProtectedRoute';
import LargeSpinnerLoader from './components/LargeSpinnerLoader';
import './styles.css';
import Metrics from './pages/Metrics';
import * as Sentry from '@sentry/react';

if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://da8881f00df5aeef1f305c3b2ef5c70d@o196420.ingest.us.sentry.io/4507980212862976',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'http://localhost:3000/',
      'localhost',
      /^https:\/\/label.ava-ai\.team\//,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const App: React.FC = () => {
  const auth = useAuth();
  if (auth.isUserScribe === null) return <LargeSpinnerLoader />;
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Routes>
        <Route
          path="/"
          element={
            auth.isUserScribe ? <Navigate to="/home" replace /> : <LoginPage />
          }
        />
        <Route
          path="/dashboard/:convoId?"
          element={
            <ProtectedRoute>
              <LabelingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/metrics"
          element={
            <ProtectedRoute>
              <Metrics />
            </ProtectedRoute>
          }
        />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Layout>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AuthProvider>
    <Router>
      <main className="app-layout">
        <App />
      </main>
    </Router>
  </AuthProvider>
);
